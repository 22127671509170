<script setup lang="ts">
import 'preline/preline'
import { EditorContent, BubbleMenu, JSONContent, useEditor } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import Code from '@tiptap/extension-code'
import OrderedList from '@tiptap/extension-ordered-list'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'

interface Props {
  disabled: boolean,
  placeholder?: string
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  placeholder: '',
})

const emit = defineEmits<
{
  'blur': [content: JSONContent]
}>()

const modelValue = defineModel<JSONContent|null>({
  required: true,
})

const editor = useEditor({
  extensions: [
    StarterKit,
    Bold.configure({
      HTMLAttributes: {
        class: 'tw-font-bold',
      },
    }),
    BulletList,
    Code,
    OrderedList,
    Link.configure({
      HTMLAttributes: {
        class: 'tw-text-blue-500 tw-underline tw-cursor-pointer',
      },
    }),
    Placeholder.configure({
      placeholder: props.placeholder,
    }),
  ],
  content: modelValue.value,
  editable: !props.disabled,
  onUpdate: ({ editor }) => {
    const json: JSONContent = editor.getJSON()
    modelValue.value = json
  },
  onBlur: ({ editor }) => {
    const json: JSONContent = editor.getJSON()
    emit('blur', json)
  },
})
</script>

<template>
  <div
    data-test-id="editor"
    :class="['tw-bg-white', { 'tw-border': !props.disabled }, 'tw-rounded', 'tw-overflow-hidden', 'tw-mx-[8px]']"
  >
    <div id="hs-editor-tiptap">
      <BubbleMenu
        v-if="!props.disabled && editor"
        :tippy-options="{ duration: 100 }"
        :editor="editor"
      >
        <div
          class="bubble-menu tw-rounded-lg tw-bg-white tw-shadow-md tw-p-1 tw-flex tw-gap-2 tw-border"
          data-test-id="editor-floating-menu"
        >
          <v-btn
            icon
            data-test-id="editor-bold"
            size="x-small"
            @click="editor.chain().focus().toggleBold().run()"
          >
            <v-icon
              color="neutral-darken3"
              size="14px"
            >
              fa:far fa-bold
            </v-icon>
          </v-btn>

          <v-btn
            icon
            data-test-id="editor-bullet-list"
            size="x-small"
            @click="editor.chain().focus().toggleBulletList().run()"
          >
            <v-icon
              color="neutral-darken3"
              size="14px"
            >
              fa:far fa-list-ul
            </v-icon>
          </v-btn>

          <v-btn
            icon
            data-test-id="editor-ordered-list"
            size="x-small"
            @click="editor.chain().focus().toggleOrderedList().run()"
          >
            <v-icon
              color="neutral-darken3"
              size="14px"
            >
              fa:far fa-list-ol
            </v-icon>
          </v-btn>

          <v-btn
            icon
            data-test-id="editor-code"
            size="x-small"
            @click="editor.chain().focus().toggleCode().run()"
          >
            <v-icon
              color="neutral-darken3"
              size="14px"
            >
              fa:far fa-code
            </v-icon>
          </v-btn>
        </div>
      </BubbleMenu>

      <editor-content :editor="editor" />
    </div>
  </div>
</template>

<style scoped lang="sass">
[data-test-id="editor"]
  border-color: transparent

  &:hover
    border-color: rgb(var(--v-theme-neutral))

  &:focus-within
    border-color: rgb(var(--v-theme-neutral-darken2))

  .bubble-menu
    border-color: rgb(var(--v-theme-neutral-lighten1))

:deep(.tiptap)
  @apply tw-px-[12px] tw-py-[10px] tw-font-normal tw-text-sm tw-outline-none

  p
    @apply tw-mb-[14px]

  p.is-editor-empty:first-child::before
    @apply tw-float-left tw-h-0 tw-pointer-events-none
    color: rgb(var(--v-theme-neutral-darken1))
    content: attr(data-placeholder)

  ul, ol
    @apply tw-pl-3 tw-mb-[14px]

  ul li
    @apply tw-list-disc tw-font-normal tw-text-sm
    p
      @apply tw-my-[6px]

  ol li
    @apply tw-list-decimal tw-font-normal tw-text-sm
    p
      @apply tw-my-[6px]

  code
    @apply tw-bg-gray-200 tw-rounded tw-text-white tw-text-sm tw-p-1
    color: rgb(var(--v-theme-neutral-darken4))

</style>
