import { RouteLocationRaw } from 'vue-router'

export enum Alignment {
  Center = 'center',
  Left = 'left',
  Right = 'right'
}

export enum Severity {
  Error = 'error',
  Success = 'success',
  Warning = 'warning'
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export enum Width {
  Grow = 'grow',
  MaxContent = 'max-content',
  MinContent = 'min-content'
}

export type Link = {
  class?: string;
  color?: string;
  flatButton?: boolean;
  icon?: string;
  target?: string;
  text: string,
  to: RouteLocationRaw;
}

export type CellData = {
  chipText?: string;
  cssClass?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  custom?: any;
  link?: Link;
  numberOfComponents?: number;
  severity?: Severity;
  subtitle?: string;
  subtitleTooltip?: {
    icon?: string;
    text: string;
  },
  text: string | number;
  unit?: string;
}

export type ColumnHeader<Key extends string = string> = {
  align?: Alignment;
  forceSummaryFormatting?: boolean;
  key: Key;
  secondaryText?: string;
  sortOptions?: SortDirection[];
  text: string;
  tooltip?: string;
  unit?: string;
  width?: Width;
}

export type RowData<Key extends string = string> = Partial<Record<Key, CellData>> & {
  children?: RowData<Key>[];
  groupingTag?: string;
  link?: Link;
  isGrayedOut?: boolean;
}
